/*
 *
 * SignupPage reducer
 *
 */

import { ContainerActions, ContainerState } from './types';

import ActionTypes from './constants';

export const initialState: ContainerState = {
  isCheckingEmail: false,
  validationEmailMessage: '',
};

function signupPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  switch (action.type) {
    case ActionTypes.RESET_DEFAULT_STATE:
      return initialState;
    case ActionTypes.CHECK_EMAIL_REQUEST:
      return {
        ...state,
        isCheckingEmail: true,
        validationEmailMessage: '',
      };
    case ActionTypes.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        isCheckingEmail: false,
        validationEmailMessage: '',
      };
    case ActionTypes.CHECK_EMAIL_FAILURE:
      return {
        ...state,
        isCheckingEmail: false,
        validationEmailMessage: action.payload,
      };
    default:
      return state;
  }
}

export default signupPageReducer;
