/*
 *
 * SignupPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const resetState = () => action(ActionTypes.RESET_DEFAULT_STATE);

export const signupSubmit = (values: any) => action(ActionTypes.SIGNUP_SUBMIT, values);

export const signup = createAsyncAction(
  ActionTypes.SIGNUP_REQUEST,
  ActionTypes.SIGNUP_SUCCESS,
  ActionTypes.SIGNUP_FAILURE,
)<void, {}, Error>();

export const checkEmailSubmit = (values: any) => action(ActionTypes.CHECK_EMAIL_SUBMIT, values);

export const checkEmail = createAsyncAction(
  ActionTypes.CHECK_EMAIL_REQUEST,
  ActionTypes.CHECK_EMAIL_SUCCESS,
  ActionTypes.CHECK_EMAIL_FAILURE,
)<void, {}, Error>();
