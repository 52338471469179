import { call, fork, put, take } from 'redux-saga/effects';
import { checkEmail as checkEmailActions, signup as signupActions } from './actions';
import { checkValidEmail, signup } from 'utils/apollo';

import ActionTypes from './constants';
import { message } from 'antd';
import { replace } from 'connected-react-router';
import translations from 'translations';
import utilsMessages from 'utils/messages';

function* signupFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.SIGNUP_SUBMIT);
    payload.lang = localStorage.getItem('lang') || 'vi';
    yield put(signupActions.request());
    delete payload.confirmPassword;
    const response = yield call(signup, {
      input: payload,
    });

    if (!response.errors) {
      yield put(signupActions.success(response));
      message.success(translations(utilsMessages.accountCreatedSuccessfully));
      yield put(replace('/kamereo-internal-signup'));
    } else {
      yield put(signupActions.failure(response.errors));
      response.errors.map((e: any) => message.error(e.message));
    }
  }
}

function* checkEmailFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.CHECK_EMAIL_SUBMIT);
    yield put(checkEmailActions.request());
    const response = yield call(checkValidEmail, {
      email: payload,
    });
    if (!response.errors) {
      yield put(checkEmailActions.success(response));
    } else {
      yield put(checkEmailActions.failure(response.errors[0].message));
    }
  }
}

// Individual exports for testing
export default function* signupPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(signupFlow);
  yield fork(checkEmailFlow);
}
